export function errorMessage(status: number): string {
  let msg: string;
  if (status === 400) msg = 'Interface error';
  else if (status === 401) msg = 'Authentication error';
  else if (status === 403) msg = 'Permission error';
  else if (status === 404) msg = 'Object not found';
  else if (status === 413) msg = 'File size exceeds the limit';
  else if (status === 415) msg = 'Interface error';
  else if (status >= 500 && status < 600) msg = 'Server error';
  else msg = 'An unknown error has occurred';
  return msg;
}
